import React from "react"
import { graphql } from "gatsby"
import MarkdownView from "react-showdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SupportOptions from "../components/supportOptions/index"
import BecomePartner from "../components/partnersList/becomePartner"
import { PostContainer, PostHeader } from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const SupportUsPage = ({ data }) => (
  <Layout>
    <AdLayout>
      <AdBanner slot="6517054732" />
      <Container main style={{ flex: "10 0 auto" }}>
        <SEO title="Support Us" description={data.strapiSeo.supportuspage} />
        <PostHeader>
          <h2 style={{ marginBottom: 16 }}>Support FGCharts</h2>
        </PostHeader>
        <PostContainer>
          <MarkdownView
            markdown={data.strapiSupportUs.SupportUs}
            options={{ table: true, emoji: true }}
          />
        </PostContainer>
        <SupportOptions
          donations={data.donations}
          general={data.general}
          affiliate={data.affiliate}
        />
        <BecomePartner
          title="Support FGCharts"
          cta="Get in Touch"
          description={data.strapiSupportUs.BoxText}
        />
      </Container>
      <AdBanner slot="6517054732" />
    </AdLayout>
  </Layout>
)

export const query = graphql`
  {
    strapiSeo {
      supportuspage
    }
    strapiSupportUs {
      SupportUs
      BoxText
    }
    donations: allStrapiSupportOptions(filter: { Type: { eq: "Donation" } }) {
      edges {
        node {
          id
          Name
          Url
          Description
          Type
          ButtonText
          Logo {
            publicURL
          }
        }
      }
    }
    general: allStrapiSupportOptions(filter: { Type: { eq: "General" } }) {
      edges {
        node {
          id
          Name
          Url
          ButtonText
          Description
          Type
          Logo {
            publicURL
          }
        }
      }
    }
    affiliate: allStrapiSupportOptions(filter: { Type: { eq: "Affiliate" } }) {
      edges {
        node {
          id
          Name
          ButtonText
          Url
          Description
          Type
          Logo {
            publicURL
          }
        }
      }
    }
  }
`

export default SupportUsPage
