import React from "react"
import styled from "styled-components"

// Assets
import { ArrowStraightIcon } from "../../images/icons"

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const StyledCard = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  background-color: #2c2a34;
  border-bottom: 4px solid
    ${({ type, theme }) => {
      if (type === "donation") {
        return theme.colors.green
      } else if (type === "general") {
        return theme.colors.primary
      } else {
        return theme.colors.orange
      }
    }};

  .card-content {
    display: grid;
    justify-items: center;
    text-align: center;
  }

  .card-img {
    max-width: 64px;
    margin-bottom: 16px;
  }

  .card-tier {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-family: "Oswald";
  }

  .card-title {
    font-weight: bold;
    font-family: "Oswald";
    font-size: 20px;
  }

  .card-url {
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
      margin-right: 8px;
      transition: all 0.25s ease-in-out;
    }

    :hover {
      span {
        margin-right: 12px;
      }
    }
  }
`

const SupportOptions = ({ donations, general, affiliate }) => {
  return (
    <div>
      <h3 style={{ marginBottom: 16, textAlign: "center" }}>Donations</h3>
      <StyledGrid>
        {donations.edges.map(({ node }) => {
          return (
            <StyledCard key={node.id} type="donation">
              <div className="card-content">
                {node.Logo && (
                  <img src={node.Logo.publicURL} className="card-img" />
                )}

                <p className="card-title">{node.Name}</p>
                <p className="card-description">{node.Description}</p>
                <a className="card-url" href={node.Url} target="_blank">
                  <span>{node.ButtonText}</span>
                  <ArrowStraightIcon />
                </a>
              </div>
            </StyledCard>
          )
        })}
      </StyledGrid>
      <h3 style={{ marginBottom: 16, marginTop: 32, textAlign: "center" }}>
        General
      </h3>
      <StyledGrid>
        {general.edges.map(({ node }) => {
          return (
            <StyledCard key={node.id} type="general">
              <div className="card-content">
                <img src={node.Logo.publicURL} className="card-img" />
                <p className="card-title">{node.Name}</p>
                <p className="card-description">{node.Description}</p>
                <a className="card-url" href={node.Url} target="_blank">
                  <span>{node.ButtonText}</span>
                  <ArrowStraightIcon />
                </a>
              </div>
            </StyledCard>
          )
        })}
      </StyledGrid>
      {affiliate.length && (
        <>
          <h3 style={{ marginBottom: 16, marginTop: 24, textAlign: "center" }}>
            Affiliates
          </h3>
          <StyledGrid>
            {affiliate.edges.map(({ node }) => {
              return (
                <StyledCard key={node.id} type="affiliate">
                  <div className="card-content">
                    <img src={node.Logo.publicURL} className="card-img" />
                    <p className="card-title">{node.Name}</p>
                    <p className="card-description">{node.Description}</p>
                    <a className="card-url" href={node.Url} target="_blank">
                      <span>{node.ButtonText}</span>
                      <ArrowStraightIcon />
                    </a>
                  </div>
                </StyledCard>
              )
            })}
          </StyledGrid>
        </>
      )}
    </div>
  )
}

export default SupportOptions
